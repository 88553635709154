<!--  класс detale_popup_wrapper и data-type нужны
    для старого запроса payment_ajax при создании заказа   -->
<!-- v-if применён к нескольким внутренним элементам из-за ошибки при закрытии попапа -->
<template>
  <Teleport to="body">
    <div
      v-if="visiblePayPopup"
      class="pay-popup _detale_popup_wrapper"
      data-type="main"
    >
      <div :class="getClassesForPopupWrapper">
        <img
          alt="close"
          src="/images/icon_close_new.png"
          class="pay-popup__close"
          @click="closePopup"
        >
        <OrderDescription
          :id-order="orderId"
          :cost="getCost"
          :cost-with-discount="getCostWithAllDiscounts"
          :user-discount="userDiscount"
          :count-companies="countCompanies"
          :with-demo-section="withDemoSection"
          :with-save-section="withSaveSection"
          :visible-demo-section="visibleDemoSection"
          :visible-save-section="visibleSaveSection"
          :visible-free-section="visibleFreeSection"
          :chosen-tab="chosenTab"
          :with-promocode="withPromocode"
          :promocode="promocode"
          :promocode-is-loading="promocodeIsLoading"
          :promocode-applied="promocodeApplied"
          :promocode-discount="promocodeDiscount"
          :discount="getDiscount"
          :client-is-auth="customerIsAuthorized"
          :email="email"
          @choose-demo-section="chooseDemoSection"
          @choose-save-section="chooseSaveSection"
          @submittedPromo="submitPromocode"
          @inputPromocode="setPromocode"
          @closePopup="closePopup"
        >
          <slot name="description" />
        </OrderDescription>

        <transition-group name="fade">
          <DemoSection
            v-show="visibleDemoSection"
            key="demoSection"
            :order-type="orderType"
            :creation-is-running="demoCreationIsRunning"
            :created-successfully="demoCreatedSuccessfully"
            :prop-email="email"
            :customer-is-authorized="customerIsAuthorized"
            @download-demo="downloadDemo"
            @choose-main-section="resetVisibleSections"
          />

          <SaveSection
            v-show="visibleSaveSection"
            key="saveSection"
            :prop-email="email"
            :customer-is-authorized="customerIsAuthorized"
            @save-order="saveOrder"
            @choose-main-section="resetVisibleSections"
          />

          <FreeSection
            v-show="visibleFreeSection"
            key="freeSection"
            :free-file-downloaded-successfully="freeFileDownloadedSuccessfully"
            :prop-email="email"
            :customer-is-authorized="customerIsAuthorized"
            @download-free="downloadFree"
            @choose-main-section="resetVisibleSections"
          />

          <PayForm
            v-show="!visibleSaveSection && !visibleDemoSection && !visibleFreeSection"
            key="payForm"
            :without-cashless="withoutCashless"
            :without-balance="withoutBalance"
            :customer-is-authorized="customerIsAuthorized"
            :cost="getCost"
            :initial-tab="initialTab"
            @init-login="initLogin"
            @change-tab="changeTab"
            @cashless-attempt="cashlessAttempt"
          >
            <template #PaymentSystems>
              <PaymentSystems
                :email="email"
                :sum="getCost"
                :customer-is-authorized="customerIsAuthorized"
                :payment-type="paymentType"
                :order-id="orderId"
                :cost="cost"
                :payment-target="paymentTarget"
                :show-order-preloader-props="showOrderPreloader"
                :discount="getDiscount"
                @inputEmail="inputEmail"
                @selectPaymentType="selectPaymentType"
                @createOrder="createOrder"
                @switchToPayment="switchToPayment"
              />
            </template>

            <template #CashlessPaymentForm>
              <CashlessPaymentForm
                :user-order-id="orderId"
                :user-count="countCompanies"
                :user-cost="getCost"
                :prop-bill-only-with-no-choice="isWithoutAct"
                @refillCashless="createBill"
              />
            </template>

            <template #BalanceForm>
              <BalanceForm
                :prop-order-id="orderId"
                :prop-client-id="clientId"
                :prop-email="email"
                :prop-cost="getCostWithAllDiscounts"
                :prop-balance="clientBalance"
                :customer-is-authorized="customerIsAuthorized"
                :prop-service="service"
              />
            </template>
          </PayForm>
        </transition-group>
      </div>
    </div>

    <div
      v-if="visiblePayPopup"
      class="pay-popup__bg"
      @click="closePopup"
    />
  </Teleport>
</template>

<script>
import DemoSection from '@/common_components/PayPopup/DemoSection/DemoSection.vue';
import SaveSection from '@/common_components/PayPopup/SaveSection/SaveSection.vue';
import OrderDescription from '@/common_components/PayPopup/OrderDescription/OrderDescription.vue';
import PayForm from './PayForm/PayForm.vue';
import PaymentSystems from './PaymentSystems/PaymentSystems.vue';
import CashlessPaymentForm from './cashlessForm/CashlessPaymentForm.vue';
import BalanceForm from './paymentByBalance/App.vue';
import api from './api';
import FreeSection from './FreeSection/FreeSection.vue';

export default {
  name: 'PayPopup',
  components: {
    FreeSection,
    CashlessPaymentForm,
    PaymentSystems,
    OrderDescription,
    SaveSection,
    DemoSection,
    PayForm,
    BalanceForm,
  },

  props: {
    visiblePayPopup: {
      type: Boolean,
      required: true,
    },

    orderType: {
      type: String,
      required: false,
      default: '',
    },

    email: {
      type: String,
      required: true,
    },

    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },

    orderId: {
      type: String,
      required: true,
    },

    cost: {
      type: Number,
      required: true,
    },

    clientId: {
      type: Number,
      required: true,
    },

    clientBalance: {
      type: Number,
      required: true,
    },

    countCompanies: {
      type: Number,
      required: true,
    },

    userDiscount: {
      type: Number,
      required: true,
    },

    withDemoSection: {
      type: Boolean,
      required: false,
      default: false,
    },

    withSaveSection: {
      type: Boolean,
      required: false,
      default: false,
    },

    // передается название раздела используется для оплаты. см. PaymentTarget.php
    paymentTarget: {
      type: String,
      required: true,
    },

    // изначально открытая секция, например с демо
    // принимаемые значения описаны в created
    initialSection: {
      type: String,
      required: false,
      default: '',
    },

    initialTab: {
      type: String,
      required: false,
    },

    demoCreationIsRunning: {
      type: Boolean,
      required: false,
      default: false,
    },

    demoCreatedSuccessfully: {
      type: Boolean,
      required: false,
      default: false,
    },

    freeFileDownloadedSuccessfully: {
      type: Boolean,
      required: false,
      default: false,
    },

    withoutCashless: {
      type: Boolean,
      required: false,
      default: false,
    },

    withoutBalance: {
      type: Boolean,
      required: false,
      default: false,
    },

    showOrderPreloader: {
      type: Boolean,
      required: false,
      default: false,
    },

    // См. Services.php
    service: {
      type: String,
      required: false,
      default: 'database',
    },

    withPromocode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    'close-popup', 'switchToPayment', 'init-login', 'download-free', 'create-order',
    'download-demo', 'create-bill', 'save-order', 'cashless-attempt', 'input-email',
  ],

  data() {
    return {
      visibleDemoSection: false,
      visibleSaveSection: false,
      visibleFreeSection: false,
      paymentType: 'sbp',
      chosenTab: '',

      promocode: '',
      promocodeIsLoading: false,
      promocodeApplied: false,
      promocodeDiscount: 0,
    };
  },

  computed: {
    getCost() {
      return (this.chosenTab === 'CashlessPaymentForm' && this.cost < 500) ? 500 : this.cost;
    },

    getCostWithUserDiscount() {
      return Math.ceil(this.cost - ((this.cost / 100) * this.userDiscount));
    },

    getCostWithAllDiscounts() {
      const promocodeDiscountSum = (this.getCostWithUserDiscount / 100) * this.promocodeDiscount;
      return Math.round(this.getCostWithUserDiscount - promocodeDiscountSum);
    },

    getClassesForPopupWrapper() {
      return {
        'pay-popup-wrapper': true,
        'hide-scroll': false,
      };
    },

    getDiscount() {
      return this.promocodeDiscount + this.userDiscount;
    },

    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
      return regExpEmail.test(this.email);
    },

    isWithoutAct() {
      return ['actualization', 'company_search_by_key'].includes(this.service);
    },
  },

  watch: {
    async orderId(newValue) {
      if (this.promocodeApplied) {
        try {
          await api.applyPromocode(newValue, this.promocode);
        } catch (e) {
          this.promocodeDiscount = 0;
          this.promocodeApplied = false;
          this.promocodeIsLoading = false;
          if (e.response && e.response.data.error) {
            echo(e.response.data.error, 6000, 'warning');
          } else {
            echo(
              'Произошла ошибка! Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
              6000,
              'error',
            );
          }
        }
      }
    },

    visiblePayPopup(newValue) {
      if (newValue) {
        this.resetVisibleSections();
        this.hideScroll();
        const initialValues = {
          demo: this.chooseDemoSection,
          save: this.chooseSaveSection,
          free: this.chooseFreeSection,
        };
        if (!this.initialSection) return;
        initialValues[this.initialSection]();
      } else {
        this.showScroll();
      }
    },
  },

  created() {
    this.resetVisibleSections();
    this.hideScroll();
    const initialValues = {
      demo: this.chooseDemoSection,
      save: this.chooseSaveSection,
      free: this.chooseFreeSection,
    };
    if (!this.initialSection) return;
    initialValues[this.initialSection]();
  },

  unmounted() {
    this.showScroll();
  },

  methods: {
    resetVisibleSections() {
      this.visibleDemoSection = false;
      this.visibleSaveSection = false;
      this.visibleFreeSection = false;
    },

    chooseDemoSection() {
      this.resetVisibleSections();
      this.visibleDemoSection = true;
    },

    chooseSaveSection() {
      this.resetVisibleSections();
      this.visibleSaveSection = true;
    },

    chooseFreeSection() {
      this.resetVisibleSections();
      this.visibleFreeSection = true;
    },

    selectPaymentType(type) {
      this.paymentType = type;
    },

    changeTab(tab) {
      this.chosenTab = tab;
    },

    cashlessAttempt() {
      this.$emit('cashless-attempt');
    },

    hideScroll() {
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    },

    showScroll() {
      document.body.style.height = 'auto';
      document.body.style.removeProperty('overflow');
    },

    closePopup() {
      this.$emit('close-popup');
    },

    inputEmail(email) {
      this.$emit('input-email', email);
    },

    async createOrder() {
      this.$emit('create-order');
    },

    switchToPayment() {
      this.$emit('switchToPayment');
    },

    createBill(data) {
      this.$emit('create-bill', data);
    },

    initLogin() {
      this.$emit('init-login');
    },

    downloadDemo(data) {
      this.$emit('download-demo', data);
    },

    downloadFree(data) {
      this.$emit('download-free', data);
    },

    saveOrder(email) {
      this.$emit('save-order', email);
    },

    async submitPromocode() {
      try {
        if (!this.isValidEmail) {
          echo(
            'Введите корректный эл. адрес',
            6000,
            'warning',
          );
        } else {
          this.setPromocodeIsLoading(true);
          const { discount } = await api.checkPromocode(
            this.promocode,
            this.getCostWithUserDiscount,
            this.email,
          );
          this.setPromocodeApplied(true);
          if (this.orderId) {
            await api.applyPromocode(this.orderId, this.promocode);
          }
          this.setPromocodeDiscount(discount);
          echo('Промокод успешно применен!', 5000, 'success');
        }
      } catch (e) {
        this.promocodeDiscount = 0;
        this.promocodeApplied = false;
        if (e.response && e.response.data.error) {
          echo(e.response.data.error, 6000, 'warning');
        } else {
          echo(
            'Произошла ошибка! Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
            6000,
            'error',
          );
        }
      } finally {
        this.setPromocodeIsLoading(false);
      }
    },

    setPromocode(value) {
      this.promocode = value;
    },

    setPromocodeIsLoading(value) {
      this.promocodeIsLoading = value;
    },

    setPromocodeApplied(value) {
      this.promocodeApplied = value;
    },

    setPromocodeDiscount(value) {
      this.promocodeDiscount = value;
    },
  },
};
</script>

  <style scoped>
  .pay-popup {
    position: fixed;
    left: 50%;
    top: 12vh;
    transform: translateX(-50%);
    z-index: 400;
    font-size: 14px;
  }

  .pay-popup__bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0);
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 202;
    display: block;
  }

  .pay-popup-wrapper {
    padding: 30px 35px;
    background-color: #fff;
    box-shadow: 0 0 1px #666;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 330px 600px;
    gap: 20px;
    max-height: 72vh;
    overflow-y: auto;
  }

  .pay-popup__close {
    max-width: 28px;
    position: absolute;
    top: -10px;
    right: -12px;
    transform: translate(-50%, 50%);
    cursor: pointer;
    z-index: 100;
    background: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .hide-scroll {
      overflow: hidden;
  }

  .fade-enter-active {
    transition: opacity .3s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  @media (width <= 800px) {
      .pay-popup {
          transform: scale(0.85) translateX(-59%);
      }
  }
</style>
