<template>
  <section class="optionBlock">
    <StaticMainBaseFilter />

    <MainBaseField
      :filter="phoneFilter"
      :field-width="fieldWidth"
      :sub-filters="phoneFilter.subFilters"
      @toggleFilter="togglePhoneHandler(false)"
      @changeFilter="togglePhoneHandler(true)"
      @changeSubFilter="changePhoneSubFilterHandler"
    />

    <MainBaseField
      :filter="addressFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleAddressHandler(false)"
      @changeFilter="toggleAddressHandler(true)"
    />
    <MainBaseField
      :filter="emailFilter"
      :field-width="fieldWidth"
      :sub-filters="emailFilter.subFilters"
      @toggleFilter="toggleEmailHandler(false)"
      @changeFilter="toggleEmailHandler(true)"
      @changeSubFilter="changeEmailSubFilterHandler"
    />
    <MainBaseField
      :filter="siteFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleSiteHandler(false)"
      @changeFilter="toggleSiteHandler(true)"
    />
    <MainBaseField
      :filter="innOgrnFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleInnOgrnHandler(false)"
      @changeFilter="toggleInnOgrnHandler(true)"
    />
    <MainBaseField
      :filter="showCompanySizeFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleCompanySizeHandler(false)"
      @changeFilter="toggleCompanySizeHandler(true)"
    />
  </section>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import StaticMainBaseFilter from '../StaticMainBaseFilter/StaticMainBaseFilter.vue';
import MainBaseField from '../Field/MainBaseField.vue';

const fieldWidth = '240px';

const store = useStore();

const phoneFilter = computed(() => store.state.mainBasePhoneFilter);
const addressFilter = computed(() => store.state.mainBaseAddressFilter);
const emailFilter = computed(() => store.state.mainBaseEmailFilter);
const siteFilter = computed(() => store.state.mainBaseSiteFilter);
const innOgrnFilter = computed(() => store.state.mainBaseInnOgrnFilter);
const showCompanySizeFilter = computed(() => store.state.mainBaseShowCompanySizeFilter);
const isPartiallySelectPossible = computed(() => store.getters.isPartiallySelectPossible);

const togglePhoneHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBasePhone', isChanged);
};

const toggleAddressHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBaseAddress', isChanged);
};

const toggleEmailHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBaseEmail', isChanged);
};

const toggleSiteHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBaseSite', isChanged);
};

const toggleInnOgrnHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBaseInnOgrn', isChanged);
};

const toggleCompanySizeHandler = (isChanged) => {
  if (isChanged && !isPartiallySelectPossible.value) {
    echo(
      'Вы не можете использовать добавление компаний с неполными контактами, '
        + 'потому что у вас выбрано только одно поле. <br> Добавьте поля в выгрузку.',
    );
    return;
  }
  store.dispatch('toggleMainBaseCompanySize', isChanged);
};

const changePhoneSubFilterHandler = (subFilterName) => {
  store.dispatch('changeMainBasePhoneSubFilter', subFilterName);
};

const changeEmailSubFilterHandler = () => {
  store.dispatch('changeMainBaseEmailVerifiedSubFilter');
};

</script>

<style scoped>
.optionBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

@media (width <= 1050px) and (width >= 800px) {
  .optionBlock {
    margin-top: 0;
  }
}
</style>
